<template>
  <div data-app>
    <head>
      <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon.png">
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
      <link rel="manifest" href="/site.webmanifest">
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5">
      <meta name="msapplication-TileColor" content="#da532c">
      <meta name="theme-color" content="#ffffff">
    </head>
    <v-card class="" id="settings" v-show="dialog">
      <v-row>
        <h1 id="logo">
          <v-icon style="transform: rotate(340deg);margin-right: 0.5em !important;color: #880E4F;">mdi-diamond-stone
          </v-icon>
          schmemo
        </h1>
      </v-row>
      <v-row class="">
        <v-text-field v-model="players.player1" class="input"
                      ref="namePlayer1"
                      label="Spieler*in 1" required></v-text-field>
        <v-text-field v-model="players.player2" class="input"
                      ref="namePlayer2"
                      label="Spieler*in 2" required></v-text-field>
        <v-select
            self-align="center"
            :items="decks"
            v-model="deckSelect"
            label="Kartendeck auswählen"
            solo
            class="input"
            style="max-width: 300px"
        ></v-select>
        <v-select
            self-align="center"
            :items="pairAmounts"
            v-model="pairAmountSelect"
            label="Anzahl Paare"
            solo
            class="input"
            style="max-width: 300px"
        ></v-select>
        <v-btn style="margin-top: 0.5em !important;" @click="saveConfig()" class="input" id="saveBtn"
               color="pink darken-4"
               :disabled="!players.player1 || !players.player2 || deckSelect === 'none' || pairAmountSelect === 0">
          <v-icon style="margin-right: 0.5em !important;">mdi-play-circle</v-icon>
          schmemo starten
        </v-btn>
      </v-row>
    </v-card>
    <div id="status" v-show="dialog === false">
      <v-row>
        <v-col
            cols="12"
            sm="1"
            md="1"
            lg="1"
            style="margin-right: 0.8em !important;"
        >
          <v-btn
              v-show="!dialog"
              @click="modal = true"
              elevation="1"
              id="reconfBtn"
          >
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-card class="card statusCard">
            <p><span class="ml-auto" v-text="players.player1" style="margin-right: 1.5em !important;"></span>
              <v-icon style="margin-right: 0.1em !important;">mdi-diamond-stone</v-icon>
              <span v-text="points.total_player1" style="margin-right: 1em !important;"></span>
              <v-icon style="margin-right: 0.1em !important;">mdi-cards-outline</v-icon>
              <span v-text="points.player1"></span></p>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="card statusCard">
            <div class="text-no-wrap">
              <v-icon style="margin-right: 0.15em !important;">mdi-cards-playing-heart-multiple-outline</v-icon>
              <strong><span v-text="turn"></span></strong> ist am Zug
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="card statusCard">
            <p><span class="ml-auto" v-text="players.player2" style="margin-right: 1.5em !important;"></span>
              <v-icon style="margin-right: 0.1em !important;">mdi-diamond-stone</v-icon>
              <span v-text="points.total_player2" style="margin-right: 1em !important;"></span>
              <v-icon style="margin-right: 0.1em !important;">mdi-cards-outline</v-icon>
              <span v-text="points.player2"></span></p>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div id="app" v-if="dialog === false" class="text-center">
      <v-card class="victoryState" v-if="this.victory" id="victory" style="max-width: 300px">
        <div>
          <div class="align-content-center">
            <div style="margin-top: 1em !important;" class="justify-center">
              <v-icon style="margin-right: 0.5em !important;">mdi-party-popper</v-icon>
              <strong v-text="winner"></strong>
            </div>
          </div>
          <div class="align-content-center mx-au">
            <div class="mt-3 justify-lg-space-around">
              <v-btn color="pink darken-4" id="newRoundBtn" v-on:click="newRound" class="btn btn-primary"
                     style="margin-top: 1em !important;">
                <v-icon style="margin-right: 0.5em !important;">mdi-fast-forward-outline</v-icon>
                Neue Runde
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
      <div v-for="(card, index) in cards" :key="index"
           :class="[{'down': card.down && !card.matched, 'up': !card.down, 'transparent': card.matched}, ' card']"
           v-on:click="handleClick(card)">
        <img :src="image(card.icon)" style="width:100%;" alt="">
      </div>
    </div>
    <template>
      <v-row justify="center">
        <v-dialog
            v-model="modal"
            max-width="790"
        >
          <v-card>
            <v-card-title class="text-h5">
              Runde wirklich beenden, um zum Hauptmenü zu gelangen?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  id="cancelModalBtn"
                  color="green darken-1"
                  text
                  @click="modal = false"
                  x-large
              >
                Zurück
              </v-btn>
              <v-btn
                  id="okModalBtn"
                  color="green darken-1"
                  text
                  @click="reconfigure()"
                  x-large
              >
                Runde Beenden
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>
<script>


import _ from "underscore";

// eslint-disable-next-line no-unused-vars
const duplicate = (arr) => {
  return arr.concat(arr).sort()
};
export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      modal: false,
      pairAmountSelect: 0,
      pairAmounts: [10, 15, 20, 25, 30, 35, 40, 44],
      pictures: [1, 2, 3,],
      winner: "",
      decks: ["Schnöpels"],
      deckSelect: "none",
      dialog: true,
      tab: 0,
      tabs: [
        {name: "Neues Spiel"}
      ],
      players: {
        'player1': '',
        'player2': ''
      },
      points: {
        player1: 0,
        player2: 0,
        total_player1: 0,
        total_player2: 0
      },
      turn: '',
      cards: [],
      image: (id) => {
        if (id !== undefined) {
          return require('/src/assets/' + id + '.jpg')
        }
        return require('/src/assets/x.png')
      },
    };
  },
  methods: {
    reconfigure() {
      this.modal = false
      this.dialog = true
      this.points.player1 = 0
      this.points.player2 = 0
    },
    newRound() {
      this.points.player1 = 0
      this.points.player2 = 0
      this.cardsShuffle()
    },
    getWinner() {
      if (this.points.player1 > this.points.player2) {
        this.winner = this.players.player1 + " gewinnt!"
        this.points.total_player1 += 1
      } else if (this.points.player1 < this.points.player2) {
        this.winner = this.players.player2 + " gewinnt!"
        this.points.total_player2 += 1
      } else {
        this.winner = "Unentschieden!"
        this.points.total_player1 += 1
        this.points.total_player2 += 1
      }
    },
    saveConfig() {
      this.turn = this.players.player1
      this.dialog = false
      this.pictures = _.range(1, this.pairAmountSelect + 1)
      this.cards = _.range(0, this.pictures.length * 2)
      this.cardsShuffle()
    },
    checkMatch(pics) {
      if (pics[0] === pics[1]) {
        console.log("it's a match");
        this.addPoint()
        return true;
      } else {
        this.switchPlayer()
      }
    },
    switchPlayer() {
      if (this.turn === this.players.player1) {
        this.turn = this.players.player2
      } else {
        this.turn = this.players.player1
      }
    },
    addPoint() {
      if (this.turn === this.players.player1) {
        this.points.player1 += 1
      } else {
        this.points.player2 += 1
      }
    },
    cardsShuffle() {
      // prep objects
      this.cards.forEach((card, index) => {
        this.cards[index] = {
          icon: '',
          down: true,
          matched: false
        }
      });
      // input every icon two times
      this.pictures.forEach((icon, index) => {
        this.cards[index].icon = icon;
        this.cards[index + this.pictures.length].icon = icon;
      });
      this.cards = _.shuffle(this.cards);
    },
    handleClick(cardClicked) {
      if (!this.runing) {
        // turn card up
        if (!cardClicked.matched && this.cardCount.cardsUp < 2) {
          cardClicked.down = false;
        }
        // when two cards are up, check if they match or turn them down
        if (this.cardCount.cardsUp === 2) {
          this.runing = true;
          setTimeout(() => {
            let match = this.checkMatch(this.cardCount.pics);
            this.cards.forEach((card) => {
              if (match && !card.down && !card.matched) {
                card.matched = true;
              } else {
                card.down = true;
              }
            });
            this.runing = false;
          }, 5500);
        }
      }
    }
  },
  mounted() {
    //this.cardsShuffle();
  },
  computed: {
    // make a count of cards up and cards matched, keep pics of cards to check in array
    cardCount: function () {
      let cardUpCount = 0;
      let cardMatchedCount = 0;
      let pics = [];
      this.cards.forEach((card) => {
        if (!card.down && !card.matched) {
          cardUpCount++;
          pics.push(card.icon);
        }
        if (card.matched) {
          cardMatchedCount++;
        }
      });
      return {
        cardsUp: cardUpCount,
        cardsMatched: cardMatchedCount,
        pics: pics
      }
    },
    // update victory state
    // eslint-disable-next-line vue/return-in-computed-property
    victory: function () {
      if (this.cardCount.cardsMatched === this.cards.length) {
        this.getWinner()
        return true;
      }
    }
  },
  head: {
    title() {
      return {
        inner: "schmemo"
      }
    },
    meta: [
      {
        name: 'msapplication-TileColor', content: 'da532c'
      }
    ],
    link: [
      {rel: "apple-touch-icon", sizes: "114x114", href: "/apple-touch-icon.png"},
      {rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png"},
      {rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png"},
      {rel: "manifest", href: "/site.webmanifest"},
      {rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#FCE4EC"}
    ]
  }
}
</script>

<style lang="scss">
body {
  background: #880E4F;
  padding: 10px;
  font-family: Helvetica, Arial, Sans-Serif;
}

#app {
  display: grid;
  grid-template-columns: repeat( auto-fit, 80px );
  grid-auto-rows: 80px;
  grid-gap: 5px;
  justify-content: center;
  perspective: 800px;
  max-width: 1080px;
  margin: 0.5em auto;
}

i.fas {
  font-size: 40px;
  color: #333;
}

.victoryState {
  grid-column-start: 9;
  grid-column-end: 12;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#app > .card {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 2px 2px 0 0 #212121;
  cursor: pointer;
  animation: flipUp .5s forwards;

  img {
    opacity: 1;
    transition: opacity .5s;
  }

  &.down {
    animation: flipDown .5s forwards;

    img {
      opacity: 0;
    }
  }

  &.matched {
    animation: matching .3s forwards;
  }
}

@keyframes flipDown {
  0% {
    background: #fff;
    transform: rotateY(0deg);
    box-shadow: 2px 2px 0 0 #212121;
  }
  100% {
    background: #F06292;
    transform: rotateY(180deg);
    box-shadow: -2px 2px 0 0 #212121;
  }
}

@keyframes flipUp {
  0% {
    background: #F06292;
    transform: rotateY(180deg);
    box-shadow: -2px 2px 0 0 #212121;
  }
  100% {
    background: #fff;
    transform: rotateY(0deg);
    box-shadow: 2px 2px 0 0 #212121;
  }
}

@keyframes matching {
  0% {
    background: #fff;
  }
  100% {
    background: lightpink;
  }
}

.transparent {
  opacity: 0.0;
}

#status {
  padding: 0em 3em 0.3em 3em;
}

#settings {
  padding: 3em 2.5em 2.5em 1em;
}

.input {
  //padding: 3em 1em 1em 3em;
  margin: 0 1em 1em 3em !important;
  //max-width: 25% !important;
}

#saveBtn {
  background-color: #FCE4EC;
  margin-right: 3em !important;
}

#newRoundBtn {
  background-color: #FCE4EC;
}

#reconfBtn {
  margin-right: 0.5em !important;
  // float: right;
}

.statusCard {
  padding: 0.4em;
}

#cancelModalBtn {
  background-color: #FCE4EC;
  margin: 0.5em;
}

#okModalBtn {
  background-color: #D81B60;
  margin: 0.5em;
}

#logo {
  color: #880E4F;
  text-indent: 50px;
  letter-spacing: 3px;
  font-family: "Nirmala UI", sans-serif;
  margin-bottom: 1em;
  text-shadow: 1px 1px #F48FB1;
  font-style: oblique;
}

#victory {
  padding: 3em;
  min-height: 13em;
}
</style>
