import Vue from 'vue'
import App from './App.vue'
import _ from 'underscore';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
const icons = [
  'fa-kiwi-bird',
  'fa-chess',
  'fa-frog',
  'fa-camera-retro',
  'fa-plug',
  'fa-anchor',
  'fa-birthday-cake',
  'fa-cube',
  'fa-dice',
  'fa-bug',
  'fa-cut',
  'fa-gem'
];
new Vue({
  render: h => h(App),
  el: "#app",
  vuetify,

  data: {
    cards: _.range(0, icons.length * 2),
    runing: false
  }
})
export default function (Vue, { head }) {
  // Add inline CSS
  head.style.push({
    type: 'text/css',
    cssText: '.some-custom-css {color: red}'
  })

  // Add an external CSS file
  head.link.push({
    rel: 'stylesheet',
    href: 'https://some-server.com/external-styleheet.css'
  })

  // Add an external JavaScript before the closing </body> tag
  head.script.push({
    src: 'https://some-server.com/external-script.js',
    body: true
  })

  // Add a meta tag
  head.meta.push({
    name: 'keywords',
    content: 'HTML,CSS,XML,JavaScript'
  })
}
